import React, { useContext, useState } from "react";
import { ModalContext } from "../../../context/ModalContext";
import { convertDateToLocalDate, formatDate } from "../../../utils/date";
import { getMediaImageURL, getMediaURL } from "../../../utils/media";
import MediaPlayer from "./MediaPlayer";
import { useRouter } from 'next/router';

const MultimediaPreview = (props: {
  className?: string,
  media: any
}) => {
  const { handleModal } = useContext(ModalContext) as unknown as {
    handleModal: (modalContent: JSX.Element) => void,
  };
  const [hover, setHover] = useState(false);
  const router = useRouter();

  const modalContent = (
    <div>
      <MediaPlayer
        url={ getMediaURL(props.media) }
        autoplay={true}
      />
      <div className="p-5">
        <h3 className="text-xl">{ props.media.title }</h3>
        <p className="font-light">{ props.media.caption }</p>
      </div>
    </div>
  )

  const handleClick = (e) => {
    e.preventDefault();
    router.push(`/multimedia`, `/multimedia/${props.media.slug}`, { shallow: true })
    if(handleModal) {
      handleModal(modalContent)

    }
  }

  return (
    <div
      className={`rounded article-container shadow-lg flex flex-col overflow-hidden cursor-pointer relative ${ props.className }`}
      onClick={handleClick}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <div className="relative overflow-hidden">
        <div
          className="article-image bg-cover bg-center h-0 aspect-16/9 hover"
          style={{
            backgroundImage: `url("${getMediaImageURL(props.media)}")`
          }}
        >
          <div className={`absolute inset-0 bg-black bg-opacity-60 transition-all text-shadow text-white flex items-center justify-center ${hover ? "opacity-100" : "opacity-0"}`}>
            Click to Play Video
          </div>
        </div>
      </div>
      <div className="p-6 flex flex-col">
        <div className="flex-1">
          <p className="font-normal text-sm opacity-40 pb-2">
            { props.media.date && formatDate(convertDateToLocalDate(props.media.date)) }
          </p>
          <h2 className="transition-all text-base font-bold">
            { props.media.title }
          </h2>
        </div>
      </div>
    </div>
  )
}

export default MultimediaPreview;
