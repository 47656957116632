import React, { useState } from "react"
import SectionWrapper from "../../Section/SectionWrapper"
import ArticlePreview from "../Preview"
import { useArticlesContext, loadLatestArticles } from "../../../../context/articlesContext";
import Loading from '../../Loading';

const LatestArticles = ( props: { latestArticles }) => {
  const { latestArticles } = props;
  
  const fetchArticleOrUndefined = (loc: number) => {
    if(latestArticles && latestArticles.length > loc) return latestArticles[loc];
    else return undefined;
  }

  if (!(latestArticles && latestArticles.length > 0)) {
    return (
      <SectionWrapper 
        title="Latest Articles"
        className="flex items-center"
        readMoreLink="articles"
        readMoreLabel="More Articles"
      >
        <div className="flex items-center rounded shadow-lg w-full h-72 mb-8">
          <Loading isLoading={true}/>
        </div>
      </SectionWrapper>
    )
  }

  return (
    <SectionWrapper 
      title="Latest Articles"
      className="grid grid-cols-12 gap-4 pb-16"
      readMoreLink="articles"
      readMoreLabel="More Articles"
    >
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(1)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(2)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(3)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(4)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(5)}
      />
      <ArticlePreview 
        className="col-span-12 md:col-span-4"
        article={fetchArticleOrUndefined(6)}
      />
    </SectionWrapper>
  )
}

export default LatestArticles;