import React from "react";
import ReactPlayer from "react-player";

const MediaPlayer = (props: { 
  url: string,
  autoplay?: boolean,
  showPreview?: boolean
}) => {
  const YouTubeOptions = {
    playerVars: {
      autoplay: props.autoplay || 0,
      controls: 1,
      modestbranding: 1
    }
  }

  const VimeoOptions = {
    playerOptions: {
      autoplay: props.autoplay || false
    }
  }

  return (
    <div className="relative w-full aspect-16/9">
      <ReactPlayer 
        width="100%"
        height="100%"
        style={{ position: "absolute", top: 0, left: 0 }}
        url={props.url}
        light={props.showPreview || false}
        config={{
          youtube: {
            ...YouTubeOptions
          },
          vimeo: {
            ...VimeoOptions
          }
        }}
        controls
      />
    </div>
  )
}

export default MediaPlayer;